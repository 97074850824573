import { gql } from "apollo-boost";

export const notificationMixin={
  methods:{
    listAnnouncements(options) {
      const getAnnouncementList = gql`
        query listAnnouncements($options: ListAnnouncementInput!){
            listAnnouncements(options: $options){
                data{
                    _id
                    title
                    message
                    createdAt
                }
                pagination{
                  total
                  totalPage
                  page
                }
            }
          }
        `;
      return this.$apollo.query({
        query: getAnnouncementList,
        variables: {
          options
        },
        fetchPolicy: 'network-only'
      }).then(response => {
        return response.data.listAnnouncements
      });
    },
    sendNotificationByAdmin(notificationData){
      let {title,message}= notificationData;
      let sendNotification = gql`
        mutation sendNotificationByAdmin($title: String!,$message:String!){
          sendNotificationByAdmin(title: $title,message:$message){
               message{
                status
                message
              }
            }
          }
        `;
      return this.$apollo.mutate({
        mutation: sendNotification,
        variables: {
          title,message
        }
      }).then(response => {
        return response.data.sendNotificationByAdmin
      }).catch(err=>{
        return err;
      });
    }
  }
}
