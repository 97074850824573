<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="sendNewNotification"
          icon-pack="feather"
          icon="icon-send"
        >Send Push Notification</vs-button>
      </div>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="announcements"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="message">Message</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].title">{{ data[indextr].title | capitalize }}</vs-td>
            <vs-td :data="data[indextr].message">{{ data[indextr].message | capitalize }}</vs-td>
            <vs-td
              :data="data[indextr].createdAt"
              style="white-space: nowrap;"
            >{{ data[indextr].createdAt | date_formatter }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
      >{{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          pagination.total - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : pagination.total
        }}
        of {{ pagination.total }}</span
      >
      <div
        class="vs-row"
        style="justify-content: space-between; display: flex; width: 100%"
      >
        <div class="vs-col vs-pagination--mb vs-xs-12 vs-sm-12 vs-lg-6" style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;"></div>
        <paginate
          :page-count="pagination.totalPage"
          :click-handler="handleChangePage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import {notificationMixin} from "../../mixins/notificationMixin";
import Paginate from "vuejs-paginate";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
  },
  props: {
    sendNotificationRoute: {
      type: String,
      default: "SuperAdminSendPushNotification"
    },
    sendSubAdminNotificationRoute: {
      type: String,
      default: "AdminSendPushNotification"
    }
  },
  mixins: [notificationMixin],
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 5
      },
      announcements:[],
      limitOptions: [5, 50, 100, 150],
      noDataText:"Announcement not available"
    };
  },
  methods: {
    async getAnnouncementList() {
      let self = this;
      this.listAnnouncements(self.dataTableParams).then((res) => {
        self.announcements = res.data;
        self.pagination = res.pagination;
      });
    },
    handleSearch(searching) {
      /*if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getNotificationList();
      }, 500);*/
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAnnouncementList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getAnnouncementList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAnnouncementList();
    },
    sendNewNotification() {
      this.$router.push({ name: 'push-notifications-send' });
    },


  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getAnnouncementList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getAnnouncementList();
      }
    }
  },

  async created() {
    await this.getAnnouncementList();
  }
};
</script>
